@import '../../styles/media-queries';

.contrast-bg-side {
  overflow: hidden;
  position: relative;
  z-index: 3;

  &__inner {
    background: var(--color-secondary-93);
    clip-path: inset(0 0 0 0 round 8rem 0 0 0);
    padding: 8.1rem 3.9rem 9.2rem;
    margin: 0 -2.1rem;

    @include for-screen-up(375) {
      clip-path: inset(0 0 0 0 round 8rem 8rem 0 0);
    }

    @include for-screen-up(640) {
      clip-path: inset(0 0 0 0 round 13.1rem 13.1rem 0 0);
      margin: 0 -3rem;
      padding: 8.1rem 6rem 13.4rem;
    }

    @include for-screen-up(1024) {
      clip-path: inset(0 0 0 0 round 14rem 14rem 0 0);
      padding: 8.1rem 6rem 14.2rem;
    }
  }

  &__logo-icon {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
  }
}
