@import '../../styles/media-queries';

.logo {
  $logo: &;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color-primary-black);
  width: 5rem;
  height: 5rem;

  &__icon {
    display: block;

    @include for-screen-up(640) {
      display: none;
    }
  }

  &_light {
    color: var(--text-color-primary-black);

    @include for-screen-up(640) {
      color: var(--text-color-primary-white);
    }
  }

  [data-mobile-type='light'] & {
    color: var(--text-color-primary-white);

    #{$logo}__icon {
      display: block;
    }

    @include for-screen-up(960) {
      color: var(--text-color-primary-black);
    }
  }
}
