@import '../../styles/media-queries';

.double-panel {
  display: flex;
  min-width: 32rem;
  flex-direction: row;
  min-height: 100vh;
  position: relative;

  &__logo-icon {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(0);
  }

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    flex: 0 1 auto;
    transform: none;
  }

  &__content {
    flex: 1 1 auto;
    min-height: inherit;
    display: flex;
    padding: 7.7rem 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }

  &__content-inner {
    width: 100%;
    max-width: 54rem;
    padding: 0 1.8rem;
    box-sizing: border-box;

    @include for-screen-up(480) {
      width: 65%;
    }

    @include for-screen-up(640) {
      width: 55%;
    }
  }

  &__side {
    display: none;

    @include for-screen-up(1024) {
      display: flex;
      flex: 0 1 auto;
      max-width: 42%;
      min-width: 42%;
      min-height: inherit;
      align-items: center;
      justify-content: center;
    }
  }
}
