@import 'node_modules/reset-css';
@import 'media-queries';
@import 'fonts';
@import 'colors';

html,
body {
  height: 100%;
}

html {
  overflow-y: scroll;
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: 11.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}

body {
  color: #40484b;
  font-size: 1.3rem;
  line-height: 1.4;
  text-align: left;
  word-wrap: break-word;
}

input,
button,
select,
textarea,
html {
  font-family: Lack, sans-serif;
}

a,
button {
  cursor: pointer;
}

a {
  color: var(--text-color-primary-black);
}

*,
*:focus,
*:active {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.link-alike {
  position: relative;
  display: inline-block;
  padding-bottom: 0.2rem;
  color: #317af6;
  cursor: pointer;

  &:after {
    @media (hover: hover) {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0.2rem;
      width: 100%;
      height: 0.1rem;
      background: currentColor;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
  }

  &:hover {
    &:after {
      @media (hover: hover) {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }
}

b,
strong {
  font-weight: bold;
}
