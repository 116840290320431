.alert {
  $alert: &;

  border-radius: 0.7rem;
  max-width: 100%;
  padding: 1.7rem 8rem 1.7rem 1.7rem;
  position: relative;

  &__text {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  &__close-btn {
    margin: auto;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    bottom: 0.5rem;
    cursor: pointer;
    line-height: 0;
  }

  &_severity {
    &-error {
      background-color: var(--color-secondary-46);
      color: var(--text-color-primary-white);
    }

    &-warning {
      background-color: var(--color-secondary-42);
      color: var(--text-color-primary-white);
    }

    &-info {
      background-color: var(--bg-color-secondary-grey);
      color: var(--text-color-primary-white);
    }

    &-success {
      background-color: var(--color-secondary-48);
      color: var(--text-color-primary-white);
    }
  }
}
