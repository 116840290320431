@import '../../styles/media-queries';

.lang-switcher {
  background: #fafafa;
  border-radius: 1.4rem;
  padding: 0.6rem 0.9rem 0.4rem;
  position: relative;
  z-index: 1;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    height: inherit;
    align-items: center;
  }

  &__item {
    margin: 0;
    padding: 0 0.45rem;

    font-size: 1.4rem;
    line-height: 1;
    color: var(--text-color-secondary);

    @include for-screen-up(1024) {
      font-size: 1.5rem;
    }
  }

  & &__link {
    background: #fafafa;
    display: block;
    padding-bottom: 0.2rem;
    color: inherit;
    text-decoration: none;
    position: relative;

    &:hover {
      color: var(--text-color-grey);
    }

    &_active {
      &,
      &:hover {
        color: var(--text-color-primary);
      }

      text-decoration: none;

      &:after {
        content: '';
        background-color: currentColor;
        height: 0.1rem;
        position: absolute;
        bottom: 0.2rem;
        left: 0;
        right: 0;
        transform: none;
      }
    }
  }
}
