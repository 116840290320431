@import '../../styles/media-queries';

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 32rem;
  position: relative;

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    flex: 0 1 auto;
  }

  & &__menu {
    display: none;

    &_visible {
      display: flex;
    }
  }

  &__content {
    min-height: 90vh;
    flex: 1 1 auto;
  }

  &__footer {
    flex: 0 1 auto;
  }

  &__logo-icon {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;

    @include for-screen-up(960) {
      z-index: 2;
    }
  }

  &__lazy-footer {
    &:not([class*='is-visible']) {
      min-height: 27.5rem;
    }
  }
}
