@font-face {
  font-family: 'Lack';
  src: url('../../public/fonts/lack/lack-regular.eot');
  src: url('../../public/fonts/lack/lack-regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/lack/lack-regular.woff2') format('woff2'),
    url('../../public/fonts/lack/lack-regular.woff') format('woff'),
    url('../../public/fonts/lack/lack-regular.svg#lackregular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../public/fonts/gilroy/Gilroy-Regular.eot');
  src: url('../../public/fonts/gilroy/Gilroy-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/gilroy/Gilroy-Regular.woff') format('woff'),
    url('../../public/fonts/gilroy/Gilroy-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../public/fonts/gilroy/Gilroy-Bold.eot');
  src: url('../../public/fonts/gilroy/Gilroy-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/gilroy/Gilroy-Bold.woff') format('woff'),
    url('../../public/fonts/gilroy/Gilroy-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../public/fonts/gilroy/Gilroy-SemiBold.eot');
  src: url('../../public/fonts/gilroy/Gilroy-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/gilroy/Gilroy-SemiBold.woff') format('woff'),
    url('../../public/fonts/gilroy/Gilroy-SemiBold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../public/fonts/gilroy/Gilroy-Medium.eot');
  src: url('../../public/fonts/gilroy/Gilroy-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/gilroy/Gilroy-Medium.woff') format('woff'),
    url('../../public/fonts/gilroy/Gilroy-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
