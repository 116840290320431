@import '../../styles/media-queries';

.notifications {
  position: fixed;
  z-index: 1500;
  right: 0.5rem;
  top: 1rem;
  max-width: none;
  left: 0.5rem;

  @include for-screen-up(768) {
    max-width: 39.4rem;
    top: 3.5rem;
    left: auto;
  }
}

.notification {
  border-radius: 0.7rem;
  box-shadow: 0 0.1rem 0.2rem #c9d6e4;
  position: relative;
  top: -2rem;
  right: 0;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  margin-bottom: 0;
  z-index: 10000;
  height: 0;
  overflow-y: hidden;

  &_visible {
    margin-bottom: 0.9rem;
    top: 0;
    opacity: 1;
    transition: all 0.25s ease-in-out;
    overflow-y: auto;
    height: auto;
  }

  a {
    color: var(--text-color-primary-blue);
  }
}

.success {
  background: green;
  color: white;
}
