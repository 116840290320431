@import '../../styles/media-queries';

.footer {
  $footer: &;
  overflow: hidden;
  position: relative;

  @include for-screen-up(640) {
    padding: 0 1rem 1rem;
  }

  @include for-screen-up(1366) {
    padding: 0 2rem 2rem;
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    margin: auto;
    box-sizing: border-box;
    flex-direction: column;

    gap: 6.4rem;
    width: 100%;
    max-width: 188rem;
    position: relative;
    z-index: 1;

    padding: 5.2rem 2.6rem 4.8rem;

    @include for-screen-up(600) {
      padding: 8.2rem 5rem 8.2rem;
      gap: 2.4rem;
    }

    @include for-screen-up(900) {
      gap: 2.8rem;
      flex-direction: row;
      padding: 12.7rem 9.5rem 4.9rem 6rem;
    }

    @include for-screen-up(1024) {
      padding: 15.3rem 9.9rem 5.2rem;
    }

    @include for-screen-up(1366) {
      padding: 15.3rem 7.5rem 7.4rem;
      gap: 9.5rem;
    }

    @include for-screen-up(1680) {
      padding: 15.3rem 23.6rem 7.4rem 9.63rem;
    }

    @include for-screen-up(1920) {
      padding: 15.3rem 36.3rem 7.4rem 9.63rem;
    }

    &:before {
      content: '';
      border-radius: 4rem 4rem 0 0;

      background: var(--bg-color-primary-blue);
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      opacity: 0;
      transform: scale(0.8) translateY(50%);
      transition: transform 0.5s ease, opacity 0.5s ease;
      will-change: transform, opacity;

      @include for-screen-up(640) {
        border-radius: 4.7rem;
      }

      @include for-screen-up(1024) {
        border-radius: 5rem;
      }

      @include for-screen-up(1366) {
        border-radius: 6rem;
      }

      #{$footer}_animation-start & {
        transform: scale(1) translateY(0%);
        opacity: 1;
      }
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
  }

  &__column {
    flex: 1 1 auto;

    @include for-screen-up(960) {
    }

    &:first-child {
      order: 4;
      flex: 0 1 auto;

      @include for-screen-up(640) {
        width: 100%;
      }

      @include for-screen-up(900) {
        order: 0;
        width: auto;
      }
    }

    &:last-child {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      gap: 6.4rem;

      @include for-screen-up(600) {
        flex-direction: row;
        justify-content: space-between;
        gap: 0;
      }
    }
  }

  &__partners {
    padding-top: 2.4rem;
    display: flex;
    justify-content: center;
    gap: 1.6rem 3.6rem;
    flex-wrap: wrap;
    max-width: 30rem;
    align-items: center;
    margin: auto;

    @include for-screen-up(600) {
      max-width: none;
    }

    @include for-screen-up(900) {
      max-width: 30rem;
    }

    @include for-screen-up(1280) {
      max-width: none;
    }

    @include for-screen-up(1366) {
      width: auto;
      gap: 4.2rem;
      padding-top: 4rem;
    }
  }

  &__partners-link {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  &__partners-img {
    display: block;
  }

  &__partners-label {
    display: block;
    font-family: Gilroy, sans-serif;
    color: var(--text-color-primary-white);
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.3;
  }
}
