@import '../../styles/media-queries';

.button {
  $button: &;

  border: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-decoration: none;
  box-sizing: border-box;
  transition: background-color 0.3s ease, transform 0.1s linear;
  white-space: nowrap;
  user-select: none;

  &__content {
    background-color: inherit;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    &:not(span) {
      @media (hover: hover) {
        transform: scale(1.02);
      }
    }
  }

  &:active {
    &:hover {
      &:not(span) {
        @media (hover: hover) {
          transform: scale(1.06);
        }
      }
    }
  }

  svg {
    display: block;
  }

  &_size {
    &-l {
      padding: 0 3.3rem;
      height: 5.9rem;
      font-size: 1.4rem;
      line-height: 1.3;
      gap: 1.2rem;

      #{$button}__before {
        margin-left: -1rem;
      }

      #{$button}__after {
        margin-right: -1rem;
      }

      @include for-screen-up(960) {
        height: 5.2rem;
        padding: 0 2.9rem;
        gap: 1.1rem;
        font-size: 1.2rem;
      }

      @include for-screen-up(1024) {
        height: 5.6rem;
        padding: 0 3.1rem;
        font-size: 1.3rem;
      }

      @include for-screen-up(1366) {
        height: 6.2rem;
        padding: 0 3.6rem;
        gap: 1.3rem;
        font-size: 1.4rem;
      }

      @include for-screen-up(1680) {
        height: 6.8rem;
        padding: 0 4rem;
        gap: 1.4rem;
        font-size: 1.6rem;
      }
    }

    &-m {
      height: 5.8rem;
      padding: 0 4.1rem;
      font-size: 1.4rem;
      line-height: 1.36;
      gap: 1.4rem;

      #{$button}__before {
        margin-left: -1rem;
      }

      #{$button}__after {
        margin-right: -1rem;
      }

      @include for-screen-up(960) {
        height: 4.9rem;
        padding: 0 2.8rem;
        gap: 1.2rem;
        font-size: 1.2rem;
      }

      @include for-screen-up(1280) {
        height: 5.8rem;
        padding: 0 4.1rem;
        font-size: 1.4rem;
        gap: 1.4rem;
      }
    }

    &-s {
      height: 4.7rem;
      padding: 0 1.875rem;
      font-size: 1.3rem;
      line-height: 1.36;
      gap: 0.9rem;

      #{$button}__before {
        margin-left: -0.5rem;
      }

      #{$button}__after {
        margin-right: -0.5rem;
      }

      @include for-screen-up(1024) {
        height: 5rem;
        padding: 0 2rem;
        font-size: 1.4rem;
        gap: 1rem;
      }
    }

    &-icon {
      padding: 0;
      width: 4.7rem;
      height: 4.7rem;

      @include for-screen-up(1024) {
        width: 5rem;
        height: 5rem;
      }
    }
  }

  &_variant {
    &-default {
      background: var(--bg-color-primary-blue);
      color: var(--text-color-primary-white);

      &:hover {
        background: var(--bg-color-primary-blue-hover);
      }

      &:active {
        background: var(--bg-color-primary-blue-active);
      }

      &:disabled {
        background: var(--color-secondary-7);
        color: var(--color-secondary-31);
      }
    }

    &-bordered {
      background: transparent;
      border: 0.1rem solid var(--bg-color-primary-blue);
      color: var(--text-color-primary-blue);

      &:hover {
        border-color: var(--bg-color-primary-blue-hover);
      }

      &:active {
        border-color: var(--bg-color-primary-blue-active);
      }

      &:disabled {
        border-color: transparent;
        background: var(--color-secondary-7);
        color: var(--color-secondary-31);
      }
    }

    &-light {
      background: var(--bg-color-primary-white);
      color: var(--text-color-primary);
      text-decoration: none;
    }

    &-link {
      color: var(--text-color-primary);
      text-decoration: none;
    }

    &-ghost {
      background: var(--bg-color-primary-lightgrey);
      color: var(--text-color-primary);

      &:hover {
        background: var(--color-secondary-7);
      }

      &:active {
        background: var(--color-secondary-7);
      }

      &:disabled {
        background: var(--color-secondary-7);
        color: var(--color-secondary-31);
      }
    }

    &-unstyled {
      background: none;
      border: 0;
      border-radius: 0;
      //padding: 0;
      text-align: left;
      white-space: normal;
    }
  }

  &_shape {
    &-round {
      border-radius: 1.6rem;

      @include for-screen-up(1024) {
        border-radius: 1.7rem;
      }
    }

    &-circle {
      border-radius: 5.4rem;

      @include for-screen-up(640) {
        border-radius: 6.7rem;
      }

      @include for-screen-up(1024) {
        border-radius: 7.1rem;
      }

      @include for-screen-up(1366) {
        border-radius: 7.6rem;
      }

      @include for-screen-up(1680) {
        border-radius: 9rem;
      }
    }
  }

  &_loading {
    cursor: wait;
  }

  &__spinner {
    animation: spin 1.2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
