@import '../../styles/media-queries';

.content {
  max-width: 92rem;
  margin: auto;
  padding: 14.2rem 2.4rem 10rem;

  @include for-screen-up(768) {
    padding: 14.2rem 4rem 10rem;
  }

  @include for-screen-up(1025) {
    padding: 18.2rem 0 10rem;
  }
}
