@import '../../styles/media-queries';

.logo-icon {
  align-items: center;
  color: var(--text-color-primary-black);
  justify-content: center;
  width: 8rem;
  height: 7.7rem;
  pointer-events: none;
  display: none;

  transform: translateY(-100%);
  will-change: transform;
  transition: transform 0.6s ease;

  &_animated {
    transition: none;
  }

  &_animated,
  [class*='animation-start'] & {
    transform: translateY(0%);
  }

  @include for-screen-up(640) {
    display: flex;
  }

  &__icon {
    display: block;
  }

  &_light {
    color: var(--text-color-primary-black);

    @include for-screen-up(640) {
      color: var(--text-color-primary-white);
    }
  }
}
