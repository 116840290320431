@import '../../styles/media-queries';

.header {
  $header: &;
  background: var(--bg-color-primary-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 7.7rem;
  padding: 1.4rem 1.8rem 1.4rem 1.5rem;
  box-sizing: border-box;
  gap: 1rem;
  z-index: 10;
  transform: translateY(-100%);
  will-change: transform;
  transition: transform 0.6s ease;

  &_with-shown-menu {
    z-index: 10;
  }

  @include for-screen-up(640) {
    background: none;
  }

  @include for-screen-up(960) {
    padding: 1.4rem 2.3rem 1.4rem 1.3rem;
  }

  @include for-screen-up(1024) {
    height: 8.2rem;
    padding: 1.4rem 2.4rem 1.4rem 1.6rem;
  }

  &_animated {
    transition: none;
  }

  &_animated,
  [class*='animation-start'] & {
    transform: translateY(0%);
  }

  &__side {
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    order: 1;
    box-sizing: border-box;
    gap: 1rem;
    position: relative;
    z-index: 1;

    @include for-screen-up(1366) {
      position: absolute;
      top: 0;
      left: 1.4rem;
      bottom: 0;
    }

    &:last-child {
      width: 100%;
      flex: 1 1 100%;
      justify-content: flex-end;
      order: 2;

      @include for-screen-up(960) {
        width: auto;
        flex: 0 1 auto;
        order: 3;
      }

      @include for-screen-up(1366) {
        left: auto;
        right: 2.4rem;
      }
    }
  }

  &__content {
    flex: 0 1 auto;
    order: 3;
    position: relative;

    @include for-screen-up(960) {
      order: 2;
      width: 100%;
      flex: 1 1 100%;
    }
  }
}
